// import { Box, Card, Grid, Typography } from '@mui/material';
// import React from 'react';
// import BeforeLoginNavbar from '../BeforeLoginNavbar';

// const HomePage = () => {
//   return (
//     <>
//       <BeforeLoginNavbar />
//       <Box sx={{width:"auto",ml:{xs:2,sm:27},mt:5,mr:2}}> 
//         <Box sx={{ml:{xs:1,sm:0}}}>
//           <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 2, }}>
//             Your Online Brokerage Platform for Stainless Steel Raw Material
//           </Typography>
//           <Typography>
//             Smarty Dalal is a cutting-edge online brokerage platform that specializes in buying and selling stainless steel raw material. With our transparent, cost-effective, and on-time services, we are revolutionizing the way stainless steel raw material is traded in the market.
//           </Typography>
//         </Box>

//         <Grid container spacing={2} sx={{ mt: 2}}>
//           <Grid item xs={12} sm={6} md={3}>
//             <Card sx={{ p: 3, height: '100%', backgroundColor: '#F0FFF0' }}>
//               <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Transparency</Typography>
//               <Typography>
//                 Transparency is at the core of our business philosophy. We believe in providing our clients with complete visibility into the buying and selling process. Our state-of-the-art online platform allows you to access real-time pricing, empowering you to make informed decisions. You can easily compare prices from multiple suppliers and select the best option that meets your requirements.
//               </Typography>
//             </Card>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <Card sx={{ p: 3, height: '100%', backgroundColor: '#F0FFF0' }}>
//               <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Cost-Effectiveness</Typography>
//               <Typography>
//                 Cost-effectiveness is another key aspect of our service. We understand that every penny counts in today's competitive business environment. That's why we strive to offer the most competitive prices in the market without compromising on the quality of stainless steel raw material. Our strong network of suppliers enables us to negotiate favorable pricing, which we pass on to our clients, helping them save on their procurement costs.
//               </Typography>
//             </Card>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <Card sx={{ p: 3, height: '100%', backgroundColor: '#F0FFF0' }}>
//               <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Time Efficiency</Typography>
//               <Typography>
//                 Time is of the essence in the business world, and we value your time. Our streamlined and efficient processes ensure that your orders are processed promptly.
//               </Typography>
//             </Card>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <Card sx={{ p: 3, height: '100%', backgroundColor: '#F0FFF0' }}>
//               <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Who We Cater To</Typography>
//               <Typography>
//                 At Smarty Dalal, we cater to a wide range of traders who rely on us for their stainless steel raw material needs. Our user-friendly online platform makes it easy for you to purchase and sell stainless steel raw material. Our responsive customer service team is always available to assist you with any queries or concerns you may have.
//               </Typography>
//             </Card>
//           </Grid>
//         </Grid>

//         <Box sx={{ mt: 3}}>
//           <Typography>
//             Smarty Dalal is your trusted online brokerage platform for buying and selling stainless steel raw material. With our commitment to transparency, cost-effectiveness, and timely services, we are the preferred choice for businesses looking to procure stainless steel raw material hassle-free. Experience the convenience and efficiency of our online platform and let us be your reliable partner for all your stainless steel raw material needs.
//           </Typography>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default HomePage;



import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { Card, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TablePagination from '@mui/material/TablePagination';
import { Tooltip as ReactTooltip } from "react-tooltip";
import config from "../../configLoader";
import BeforeLoginNavbar from '../../Components/BeforeLoginNavbar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function HomePage() {

  const bidAmountRef = useRef();
  const autoBidTillRef = useRef();
  const [filterStatus, setFilterStatus] = useState('Live');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterGrade, setFilterGrade] = useState('');
  const [requirementData, setRequirementData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [bid_amount, setBid_Amount] = useState({});
  const [AutoBidAmount, setAutoBidAmount] = useState({});
  const [showBidAmount, setShowBidAmount] = useState(false);
  const [CategoryTypeData, setCategoryTypeData] = useState([]);
  const [GradeTypeData, setGradeTypeData] = useState([]);
  const [StatusTypeData, setStatusTypeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoBidValue, setAutoBidValue] = useState(null);
  const [filterDate, setFilterDate] = useState([null, null]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newMaxAmount, setNewMaxAmount] = useState("");
  const [autoBidMaxAmount, setAutoBidMaxAmount] = useState("");
  const [requirementId, setRequirementId] = useState("");

  const Type = "I want to Buy"

  const key = JSON.parse(localStorage.getItem('key'));
  const secret = JSON.parse(localStorage.getItem('secret'));
  const UserId = JSON.parse(localStorage.getItem('userId'));

  useEffect(() => {
    const mobile = JSON.parse(localStorage.getItem("mobile"));
    const fetchData = async () => {
      const result = await fetch(`${config.apiUrl}steel.api.get_single_profile?mobile=8769513395`, {
      })
      const res = await result.json();
      const valuesArray = res.data[0].category.reduce((arr, obj) => {
        return arr.concat(Object.values(obj));
      }, []);

      const response = await fetch(`${config.apiUrl}steel.api.get_requirement`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        //   "Authorization": `token ${key}:${secret}`,
        // },
        body: JSON.stringify
          ({
            categories: valuesArray,
            page: page + 1,
            size: rowsPerPage,
            type: Type,
            status: filterStatus,
            category: filterCategory,
            grade: filterGrade,
            from_date: filterDate[0],
            to_date: filterDate[1]
          })
      });

      const responseData = await response.json();

      // const dataWithHoursLeft = responseData.data.requirement.map((row) => {
      //   const expirationDate = new Date(row.expiration_date);
      //   const currentDate = new Date();
      //   const timeDiff = expirationDate.getTime() - currentDate.getTime();
      //   const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60));
      //   const minutesLeft = Math.floor(
      //     (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
      //   );
      //   return { ...row, hoursLeft, minutesLeft };
      // });
      // setRequirementData(dataWithHoursLeft);
      // setTotalCount(responseData.data.total_requirement[0].count);
      setLoading(false);
    };

    fetchData();
  }, [page, rowsPerPage, filterCategory, filterStatus, filterGrade, filterDate]);


  const handleEditClick = (auto_bid_max_amount, name) => {
    setAutoBidMaxAmount(auto_bid_max_amount);
    setRequirementId(name)
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setNewMaxAmount(inputValue);
    }
  };


  const handleUpdateMaxAmount = async () => {

    if (Type === "I want to Sale" && newMaxAmount <= autoBidMaxAmount) {
      toast.error("Please enter an amount greater than the current max amount.");
      return;
    } else if (Type !== "I want to Sale" && newMaxAmount >= autoBidMaxAmount) {
      toast.error("Please enter an amount less than the current max amount.");
      return;
    }

    const result = await fetch(`${config.apiUrl}steel.api.update_my_bid`,
      {
        method: "PUT",
        // headers: {
        //   "Content-Type": "application/json",
        //   Authorization: `token ${key}:${secret}`,
        // },
        body: JSON.stringify({
          requirement: requirementId,
          auto_bid_max_amount: autoBidMaxAmount,
          new_max_amount: newMaxAmount,
        }),
      }
    );
    const res = await result.json();
    if (res.status_code === 200) {
      toast.success(res.message)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    else {
      toast.error("Please enter correct amount.");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setOpenDialog(false);
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setRequirementData((prevData) => {
        const updatedData = prevData.map((row) => {
          if (row.hoursLeft > 0 || row.minutesLeft > 0) {
            if (row.minutesLeft === 0) {
              if (row.hoursLeft === 0) {
                row.hoursLeft = 0;
                row.minutesLeft = 0;
              } else {
                row.hoursLeft -= 1;
                row.minutesLeft = 59;
              }
            } else {
              row.minutesLeft -= 1;
            }
          }
          return row;
        });
        return updatedData;
      });
    }, 60000); // Update every minute (60,000 milliseconds)

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [setRequirementData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClearFilters = () => {
    setFilterStatus('Live');
    setFilterCategory('');
    setFilterGrade('');
    setFilterDate([null, null])
  };

  const handleDateChange = (date) => {
    if (date && date[0] && date[1]) {
      const formattedStartDate = date[0].format('YYYY-MM-DD');
      const formattedEndDate = date[1].format('YYYY-MM-DD');
      setFilterDate([formattedStartDate, formattedEndDate]); // Update setFilterDate with an array of start and end dates
    }
  };

  useEffect(() => {
    const fetchCategoryType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_category`, {
          // headers: {
          //   "Content-Type": "application/json",
          //   "Authorization": `token ${key}:${secret}`
          // }
        });
        const data = await response.json();
        setCategoryTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchGradeType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_grade`, {
          // headers: {
          //   "Content-Type": "application/json",
          //   "Authorization": `token ${key}:${secret}`
          // }
        });
        const data = await response.json();
        setGradeTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchStatusType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_requirement_status`, {
          // headers: {
          //   "Content-Type": "application/json",
          //   "Authorization": `token ${key}:${secret}`
          // }
        });
        const data = await response.json();
        setStatusTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategoryType();
    fetchGradeType();
    fetchStatusType();

  }, [key, secret]);


  const InputFieldData = (e, cardId) => {
    const { name, value } = e.target;
    if (name === 'bid_amount') {
      if (value === '' || /^\d+(\.\d{0,2})?$/.test(value)) {
        setBid_Amount(prevBidAmounts => ({
          ...prevBidAmounts,
          [cardId]: value
        }));
      }
    }
  };

  const NewInputFieldData = (e, cardId) => {
    const { name, value } = e.target;
    if (name === 'AutoBidAmount') {
      if (value === '' || /^\d+(\.\d{0,2})?$/.test(value)) {
        setAutoBidAmount(prevAutoBidAmounts => ({
          ...prevAutoBidAmounts,
          [cardId]: value
        }));
      }
    }
  };

  const toggleButtonData = (event, cardId) => {
    const autobidValue = event.target.checked ? 1 : 0;
    setAutoBidValue(autobidValue);
    setShowBidAmount(event.target.checked);
    setSelectedCard(cardId);
    setAutoBidAmount('');

    if (autobidValue) {
      setTimeout(() => {
        autoBidTillRef.current.focus();
      }, 0);
    }
  };

  //bid now button function
  const BidNewData = async (e, row, autoBidValue) => {
    e.preventDefault();

    if (autoBidValue && AutoBidAmount === '') {
      toast.warn('You have not entered any value in auto bid');
      return;
    }

    const newBidAmount = parseFloat(bid_amount[row.name]);
    const newAutoBidAmount = parseFloat(AutoBidAmount[row.name]);

    if (isNaN(newBidAmount) || newBidAmount <= 0) {
      toast.error('Please enter a valid bid amount!');
      return;
    }

    const parsedValue = parseFloat(newBidAmount);
    if (isNaN(parsedValue)) {
      setBid_Amount('');
    } else {
      const roundedValue = Math.round(parsedValue * 100) / 100; // Round to two decimal places
      const isValid = roundedValue % 0.25 === 0; // Check if value is a multiple of 0.25

      if (isValid) {
        setBid_Amount(roundedValue.toFixed(2)); // Set the valid value with two decimal places
      } else {
        toast.error('Please enter a valid bid amount! Only multiples of 0.25 are allowed.');
        return;
      }
    }

    const newParsedValue = parseFloat(newAutoBidAmount);
    if (isNaN(newParsedValue)) {
      setAutoBidAmount('');
    } else {
      const newRoundedValue = Math.round(newParsedValue * 100) / 100; // Round to two decimal places
      const isNewValid = newRoundedValue % 0.25 === 0; // Check if value is a multiple of 0.25

      if (isNewValid) {
        setAutoBidAmount(newRoundedValue.toFixed(2)); // Set the valid value with two decimal places
      } else {
        toast.error('Please enter a valid auto bid amount! Only multiples of 0.25 are allowed.');
        return;
      }
    }


    const lowestBidAmount = parseFloat(row.lowest_bid);
    const highestBidAmount = parseFloat(row.highest_bids)
    if (row.requirement_type === "I want to Sale") {
      if (newBidAmount <= highestBidAmount) {
        toast.warn(`Your bid amount must be greater than the highest bid (${highestBidAmount})`);
        return;
      }
      if (newBidAmount <= highestBidAmount + 0.24) {
        toast.warn(`Your bid amount must be at least 0.25 greater than the highest bid (${highestBidAmount + 0.25})`);
        return;
      }
      if (newAutoBidAmount <= newBidAmount) {
        toast.warn(`Your auto bid amount must be greater than the highest bid (${newBidAmount})`);
        return;
      }

    } else if (row.requirement_type === "I want to Buy") {
      if (newBidAmount >= lowestBidAmount) {
        toast.warn(`Your bid amount must be less than the lowest bid (${lowestBidAmount})`);
        return;
      }
      if (newBidAmount >= lowestBidAmount - 0.24) {
        toast.warn(`Your bid amount must be at least 0.25 less than the lowest bid (${lowestBidAmount - 0.25})`);
        return;
      }
      if (newAutoBidAmount >= newBidAmount) {
        toast.warn(`Your auto bid amount must be less than the lowest bid (${newBidAmount})`);
        return;
      }

    }
    let message = `Your Bid Amount is ${newBidAmount} for the item ${row.category}`;
    if (newAutoBidAmount) {
      message += `\nAutobid Amount: ${newAutoBidAmount}`;
    }
    const confirmed = window.confirm(message);
    if (!confirmed) {
      return;
    }
    try {
      const MobileData = JSON.parse(localStorage.getItem("mobile"));
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0]; // format date as YYYY-MM-DD
      const formattedTime = currentDate.toLocaleTimeString('en-US', { hour12: false });
      const user = localStorage.getItem("email");
      const response = await fetch(`${config.apiUrl}steel.api.create_new_bid`,
        {
          method: "POST",
          body: JSON.stringify({
            bid_amount: newBidAmount,
            requirement: row.name,
            bid_time_date: `${formattedDate} ${formattedTime}`, // use formatted date instead of row.date
            user: user,
            profile: MobileData,
            auto_bid: autoBidValue,
            auto_bid_max_amount: newAutoBidAmount
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${key}:${secret}`
          }
        }
      );
      const data = await response.json();
      if (data.status_code === 404) {
        toast.error(data.message);
      } else {
        toast.success("Congratulations! Bid Created Successfully..!");
        setTimeout(() => {
          window.location.reload();
          setBid_Amount('');
          setAutoBidAmount('');
          bidAmountRef.current.value = '';
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event, row) => {
    if (event.key === 'Enter') {
      BidNewData(event, row);
    }
  }

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h5" color="inherit" sx={{ ml: 2, color: "white" }}>
            Please Wait...
          </Typography>
        </Box>
      ) : (
        <>
          <BeforeLoginNavbar/>
          <Box sx={{ mt: 5, ml: { xs: 2, sm: 27 }, mr: 2, pl: 1, pr: 1, width: "auto", borderRadius: "5px" }}>
            <ToastContainer style={{ fontSize: "13px" }} />
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", fontSize: "25px", mb: 2 }}>Live Requirements</Typography>
            <Box sx={{ display: "flex", alignItems: "start", mt: 2, mb: 2, flexDirection: { xs: "column", sm: "row" } }}>
              <FormControl sx={{ width: { xs: "90%", sm: "150px" }, mt: { xs: 2, sm: 1 }, mr: { xs: 0, sm: 1 } }}>
                <InputLabel id="demo-multiple-name-label" sx={{ fontFamily: "sans-serif" }} size='small'>Status</InputLabel>
                <Select
                  size='small'
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={filterStatus}
                  onChange={(event) => setFilterStatus(event.target.value)}
                  input={<OutlinedInput label="Status" />}
                  sx={{ width: "100%", fontFamily: "sans-serif" }}
                >
                  {StatusTypeData.map((option) => (
                    <MenuItem key={option.status} value={option.status}>
                      {option.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "90%", sm: "150px" }, mt: 1, mr: { xs: 0, sm: 1 } }}>
                <InputLabel id="demo-multiple-name-label" sx={{ fontFamily: "sans-serif" }} size="small">Category</InputLabel>
                <Select
                  size="small"
                  sx={{ fontFamily: "sans-serif" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={filterCategory}
                  onChange={(event) => setFilterCategory(event.target.value)}
                  input={<OutlinedInput label="Category" />}
                >
                  {CategoryTypeData.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "90%", sm: "150px" }, mt: 1, mr: 1 }}>
                <InputLabel id="demo-multiple-name-label" size="small" sx={{ fontFamily: "sans-serif" }}>Grade</InputLabel>
                <Select
                  size="small"
                  sx={{ fontFamily: "sans-serif" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={filterGrade}
                  onChange={(event) => setFilterGrade(event.target.value)}
                  input={<OutlinedInput label="Grade" />}
                >
                  {GradeTypeData.map((option) => (
                    <MenuItem key={option.grade} value={option.grade}>
                      {option.grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['SingleInputDateRangeField']}>
                  <DateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    onChange={handleDateChange}
                    format="DD-MM-YYYY"
                    value={filterDate}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Box sx={{ display: "flex", flexDirection: "row" }}>

                <Button sx={{
                  background: "#2F4F4F",
                  "&:hover": {
                    background: "#2E8B57"
                  }, ml: 1, mt: "12px", fontFamily: "sans-serif"
                }} size='small' variant="contained" onClick={handleClearFilters}>
                  Clear
                </Button>
              </Box>
            </Box>
            <Box >
              <Grid container spacing={2}>
                {requirementData.length === 0 ? (
                  <Box sx={{ display: "flex", ml: { xs: 2, sm: 40 } }}>
                    <Typography
                      sx={{
                        p: 10,
                        color: "#B8390E",
                        fontSize: 24,
                        fontWeight: 'bold',
                        textAlign: "center",
                      }}
                    >No data Found..!</Typography>
                  </Box>
                ) : (
                  requirementData.map((row) => (
                    <Grid key={row.name} item xs={12} sm={6} md={3} lg={6}>
                      <Card
                        sx={{
                          py: 2, px: 2,
                          borderRadius: "5px",
                          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                          width: "auto",
                          height: { xs: "auto", sm: "auto" },
                          border: "1px solid #078586",
                          backgroundColor: "#F0FFF0"
                        }}>
                        <Typography sx={{ pb: 3, fontSize: 16, fontWeight: "bold", color: "#078586" }}>{row.title}</Typography>
                        <Grid container>
                          <Grid container rowSpacing={1} columnSpacing={1}>
                            <Grid item xs={6}>
                              <Item sx={{ fontFamily: "sans-serif", textAlign: "start", height: "275px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Grade:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.grade}</Grid>
                                  {row.category === "Square Pipe" || row.category === "Square/Hex Rod" ?
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Size:</Grid>
                                      {row.category === "Square/Hex Rod" ?
                                        <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}</Grid> :
                                        <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}x{row.size_2}</Grid>
                                      }
                                    </>
                                    : null}
                                  {row.category === "Round Rod" || row.category === "Square/Hex Rod" || row.category === "Wire" ?
                                    null :
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Thickness(mm):</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.thickness}</Grid>
                                    </>
                                  }
                                  {row.category === "Round Pipe" || row.category === "Square Pipe" || row.category === "Round Rod" || row.category === "Square/Hex Rod" || row.category === "Wire" ?
                                    null :
                                    <><Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Width(mm):</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.width_value}</Grid>
                                    </>
                                  }
                                  {row.category === "Round Pipe" || row.category === "Round Rod" || row.category === "Wire" ?
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Diameter(mm):</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.diameter}</Grid>
                                    </> :
                                    null
                                  }
                                  {row.category === "Coil" ?
                                    null
                                    :
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Length(mm):</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.length_value}</Grid>
                                    </>
                                  }
                                  {row.category === "Wire" || row.finish === "" || row.finish === null ? null : <>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Finish:</Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.finish}</Grid>
                                  </>}
                                  {row.make === "" || row.make === null ? null : <>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Make:</Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.make}</Grid>
                                  </>}
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Quantity:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.quantity} ({row.uom})</Grid>
                                  {row.weight === 0 ? null :
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Weight(kg):</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.weight}</Grid>
                                    </>}
                                  {row.category === "Wire" ?
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Hardness Type:</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.hardness_type}</Grid>
                                    </> :
                                    null}
                                  {row.category === "Round Pipe" ?
                                    <>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>ERW/Seamless:</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.erw_seamless}</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>NB/OD:</Grid>
                                      <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.nb_od}</Grid>
                                    </> :
                                    null}
                                  {row.category === "Round Rod" || row.category === "Square/Hex Rod" ? (
                                    row.shape !== null ? (
                                      <>
                                        <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, mb: 1 }}>Shape:</Grid>
                                        <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.shape}</Grid>
                                      </>
                                    ) : null
                                  ) : null}
                                </Grid></Item>
                            </Grid>


                            <Grid item xs={6}>
                              <Item sx={{ fontFamily: "sans-serif", textAlign: "start", height: "275px" }}>
                                <form onSubmit={(e) => BidNewData(e, bid_amount)}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 } }}>
                                      {!(row.status !== "Live" ||
                                        (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                        (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId)) ? (
                                        <Grid item xs={6} sm={6}>
                                          {row.requirement_type === "I want to Buy" ? (
                                            <Typography sx={{ color: "#7272FF", fontSize: { xs: 10, sm: 12 }, fontFamily: "sans-serif" }}>
                                              {row.lowest_bid !== "NO BIDS" ? (
                                                `Allowable: ${row.lowest_bid - 0.25}`
                                              ) : (
                                                "No Bid Now"
                                              )}
                                            </Typography>
                                          ) : (
                                            <Typography sx={{ color: "#7272FF", fontSize: { xs: 10, sm: 12 }, fontFamily: "sans-serif" }}>
                                              {row.highest_bids !== "NO BIDS" ? (
                                                `Allowable: ${row.highest_bids + 0.25}`
                                              ) : (
                                                "No Bid Now"
                                              )}
                                            </Typography>
                                          )}
                                        </Grid>
                                      ) : null}

                                    </Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                      <input
                                        onKeyPress={(e) => handleKeyPress(e, row)}
                                        disabled={
                                          (row.status !== "Live") ||
                                          (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                          (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId)
                                        }
                                        ref={bidAmountRef}
                                        placeholder="Bid Rate"
                                        name='bid_amount'
                                        onChange={(e) => InputFieldData(e, row.name)}
                                        value={bid_amount[row.name] || ''}
                                        type="text"
                                        style={{
                                          border: "1px solid black",
                                          borderRadius: "5px",
                                          width: "100%",
                                          padding: "5px",
                                        }}
                                        data-tooltip-id={(row.status !== "Live") ||
                                          (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                          (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId) ? "my-tooltip-1" : null}
                                      />
                                      <ReactTooltip
                                        id="my-tooltip-1"
                                        place="right"
                                        variant="info"
                                        content="Your bid is the best at the moment"
                                      />
                                    </Grid>
                                    {
                                      (row.status !== "Live") ||
                                        (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                        (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId) ?
                                        null
                                        :
                                        <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>
                                        </Grid>
                                    }
                                    <Grid item xs={(row.status !== "Live") ||
                                      (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                      (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId) ? 12 : 6} sm={(row.status !== "Live") ||
                                        (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                        (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId) ? 12 : 6} sx={{ fontSize: 14, textAlign: { xs: "end", sm: "start" } }}>

                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Typography sx={{ fontSize: { xs: 10, sm: 12 }, fontFamily: "sans-serif" }}>Auto Bid</Typography>
                                        <FormGroup sx={{ marginLeft: "6px" }}>
                                          <FormControlLabel
                                            control={
                                              <Android12Switch
                                                sx={{ mt: -1 }}

                                                disabled={
                                                  (row.status !== "Live") ||
                                                  (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                                  (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId)
                                                }
                                                onChange={(event) => toggleButtonData(event, row.name)}
                                                checked={selectedCard === row.name && showBidAmount}
                                              />

                                            }
                                          />
                                        </FormGroup>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                      {showBidAmount && selectedCard === row.name && (
                                        <input
                                          ref={autoBidTillRef}
                                          placeholder="Lower Limit"
                                          type="text"
                                          name='AutoBidAmount'
                                          value={AutoBidAmount[row.name] || ''} // Use the autoBidAmounts object for value
                                          onChange={(e) => NewInputFieldData(e, row.name)}
                                          style={{
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                            width: "100%",
                                            padding: "5px",
                                            marginTop: "-10px"

                                          }}
                                        />
                                      )}
                                    </Grid>

                                    {row.all_bids.length > 0 ? (
                                      <>
                                        {row.all_bids.map((item) => (
                                          <>
                                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, color: "#7272FF" }}>
                                              Auto Bid Rate: {item.auto_bid_max_amount}
                                            </Grid>
                                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" }, color: "#7272FF" }}>
                                              {item.auto_bid === 1 && item.bid_by === "Bid by you" && (
                                                <Button variant="contained" sx={{
                                                  background: "#2F4F4F",
                                                  fontFamily: "sans-serif",
                                                  fontSize: { xs: 7, sm: 10 }, "&:hover": {
                                                    background: "#2E8B57"
                                                  },
                                                }}
                                                  size="small"
                                                  onClick={() => handleEditClick(item.auto_bid_max_amount, row.name)}
                                                >Modify auto Bid</Button>
                                              )}
                                            </Grid>
                                          </>
                                        ))}
                                      </>
                                    ) : null}


                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Status:</Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.status}</Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>
                                      Time Left:
                                    </Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" }, color: "#EE4B2B" }}>
                                      {new Date() < new Date(row.expiration_date) ? (
                                        row.hoursLeft > 24 ? (
                                          `${Math.floor(row.hoursLeft / 24)} days ${row.hoursLeft % 24} hours ${row.minutesLeft} minutes`
                                        ) : (
                                          `${row.hoursLeft} hours ${row.minutesLeft} minutes`
                                        )
                                      ) : (
                                        "Expired"
                                      )}
                                    </Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Best Bid:</Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.lowest_bid}</Grid>
                                    <Grid item xs={6} sm={6}>
                                      {row.lowest_bid !== "NO BIDS" ? (
                                        <Link to={"/selleruserallbids/" + row.name} style={{ textDecoration: "none" }}>
                                          <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                              background: "#2F4F4F",
                                              mt: 1,
                                              fontFamily: "sans-serif",
                                              fontSize: { xs: 8, sm: 10 },
                                              "&:hover": {
                                                background: "#2E8B57",
                                              },
                                            }}
                                          >
                                            Bid Log
                                          </Button>
                                        </Link>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          size="small"
                                          sx={{
                                            background: "#2F4F4F",
                                            mt: 1,
                                            fontFamily: "sans-serif",
                                            fontSize: { xs: 8, sm: 10 },
                                          }}
                                          disabled
                                        >
                                          Bid Log
                                        </Button>
                                      )}
                                    </Grid>

                                    <Grid item xs={6} sm={6}>
                                      <Button
                                        variant="contained"
                                        size='small'
                                        sx={{
                                          background: "#2F4F4F", fontFamily: "sans-serif", fontSize: { xs: 8, sm: 10 }, mt: 1, "&:hover": {
                                            background: "#2E8B57"
                                          },
                                        }}
                                        onClick={(e) => BidNewData(e, row, autoBidValue)}
                                        disabled={
                                          (row.status !== "Live") ||
                                          (row.requirement_type === "I want to Sale" && row.highest_bid_user === UserId) ||
                                          (row.requirement_type === "I want to Buy" && row.lowest_bid_user === UserId)
                                        }
                                      >
                                        Bid Now
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </form>
                              </Item>
                            </Grid>


                            <Grid item xs={12} sm={12}>
                              <Item sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
                                <Grid item xs={6} sm={6}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: 14, fontSize: { xs: 10, sm: 12 } }}>
                                      Payment Terms:
                                    </Grid>
                                    <Grid item xs={6} sm={6} sx={{ fontSize: 14, fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                      {row.payment_terms}
                                    </Grid>
                                    {row.remarks === "" || row.remarks === null ? null :
                                      <>
                                        <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: 14, fontSize: { xs: 10, sm: 12 } }}>
                                          Remarks:
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ fontSize: 14, fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                          {row.remarks}
                                        </Grid>
                                      </>
                                    }
                                    {row.city === "" || row.city === null ? null :
                                      <>
                                        <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: 14, fontSize: { xs: 10, sm: 12 } }}>
                                          Delivery at:
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ fontSize: 14, fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                          {row.city}
                                        </Grid>
                                      </>}
                                  </Grid>
                                </Grid>
                              </Item>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
            {/* pagination  */}
            <Box sx={{ mt: 2 }}>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>

          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle sx={{ fontFamily: "sans-serif" }}>Update New Max Amount</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="New Max Amount"
                type="text"
                fullWidth
                value={newMaxAmount}
                onChange={handleChange}
                size="small"
                sx={{ fontFamily: "sans-serif" }}
              />
              <Typography>{autoBidMaxAmount && (
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold", fontFamily: "sans-serif" }}>
                  Current Auto Max Amount: <span style={{ color: "#078586", fontFamily: "sans-serif" }}>{autoBidMaxAmount}</span>
                </Typography>
              )}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} size="small" sx={{
                background: "#2F4F4F", fontFamily: "sans-serif", color: "white", "&:hover": {
                  background: "#078586",
                }
              }}>Cancel</Button>
              <Button sx={{
                background: "#2F4F4F", fontFamily: "sans-serif", color: "white", "&:hover": {
                  background: "#078586",
                }
              }} onClick={handleUpdateMaxAmount} variant="contained" color="primary" size="small">Modify</Button>
            </DialogActions>
          </Dialog>
          
        </>
      )}
    </>
  );
}