import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { format } from 'date-fns';
import { ToastContainer, toast } from "react-toastify";
import config from "../../../configLoader";

const SellerUserAllBids = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [BidData, setBidData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newMaxAmount, setNewMaxAmount] = useState("");
  const [autoBidMaxAmount, setAutoBidMaxAmount] = useState("");
  const key = JSON.parse(localStorage.getItem('key'));
  const secret = JSON.parse(localStorage.getItem('secret'));

  const requirementType = "I want to Sale";

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setNewMaxAmount(inputValue);
    }
  };


  const handleBack = () => {
    navigate("/liverequirements");
  }

  const handleEditClick = (auto_bid_max_amount) => {
    setAutoBidMaxAmount(auto_bid_max_amount);
    setOpenDialog(true);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  const handleUpdateMaxAmount = async () => {
    // Validate the newMaxAmount based on requirement_type
    if (requirementType === "I want to Sale" && newMaxAmount <= autoBidMaxAmount) {
      toast.error("Please enter an amount greater than the current max amount.");
      return;
    } else if (requirementType !== "I want to Sale" && newMaxAmount >= autoBidMaxAmount) {
      toast.error("Please enter an amount less than the current max amount.");
      return;
    }

    // Proceed with updating the max amount
    const result = await fetch(`${config.apiUrl}steel.api.update_my_bid`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${key}:${secret}`,
        },
        body: JSON.stringify({
          requirement: params.id,
          auto_bid_max_amount: autoBidMaxAmount,
          new_max_amount: newMaxAmount,
        }),
      }
    );
    const res = await result.json();
    if (res.status_code === 200) {
      toast.success("New Max Amount Modified Successfully..!")
    }
    setOpenDialog(false);
  };



  useEffect(() => {
  const AllBidsData = async () => {
    let result = await fetch(`${config.apiUrl}steel.api.get_bids_by_requirement?requirement=${params.id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${key}:${secret}`
      }
    });
    let res = await result.json();
    setBidData(res.message);
    // setBuyerUser(res.message[0].requirement_type);
  }
  AllBidsData();
}, []);

  return (
    <>
      <Box
        sx={{
          ml: { xs: 0, sm: 27 },
          mt: 10,
          mr: { xs: 0, sm: 2 },
          width: { xs: "auto", sm: "auto" }
        }}
      >
        <ToastContainer />
        <Button variant="contained" sx={{
          ml: 3, mt: 3, mb: 1, borderRadius: 100, background: "#2F4F4F", color: "white", "&:hover": {
            background: "#078586",
          }
        }} onClick={handleBack}><ArrowBackIosNewIcon fontSize="small" /></Button>
        {BidData.length === 0 ? (
          <Typography sx={{ fontWeight: "bold", fontSize: 30, color: "#B8390E", textAlign: "center", pb: 5, fontFamily:"sans-serif" }}>Opps..! Bid Not Found</Typography>
        ) : (
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            {BidData.map((row, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div key={row.id} style={{ margin: "20px" }}>
                  <Card variant="outlined" sx={{
                    width: "100%",
                    border: "1px solid #078586",
                    backgroundColor: "#F0FFF0"
                  }}>
                    <CardContent>
                      <Typography sx={{ mb: 1.5, fontFamily:"sans-serif", fontWeight: "Bold" }} variant="h5" component="div">
                        Bid Amount: {row.bid_amount}
                      </Typography>
                      {/* <Typography color="primary" sx={{ mb: 1.5 }}>
                        Status: {row.status}
                      </Typography> */}
                      <Typography sx={{ mb: 1.5, fontFamily:"sans-serif" }}>
                        Creation: {format(new Date(row.creation), 'dd-MM-yyyy hh:mm a')}
                      </Typography>
                      {row.bid_by === "Bid by you" ?
                        <span style={{ color: "green", fontWeight: "bold", marginBottom: 1.5, fontFamily:"sans-serif" }}>
                          {row.bid_by}
                        </span>
                        :
                        <span style={{ fontWeight: "bold", marginBottom: 1.5, fontFamily:"sans-serif" }}>
                          {row.bid_by}
                        </span>
                      }

                      {row.auto_bid === 1 && row.bid_by === "Bid by you" && (
                        <Button variant="contained" sx={{
                          float: "right", background: "#2F4F4F", fontFamily:"sans-serif", color: "white", "&:hover": {
                            background: "#078586",
                          }
                        }}
                          size="small"
                          onClick={() => handleEditClick(row.auto_bid_max_amount)}
                        >Modify Auto Bid</Button>
                      )}
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle sx={{fontFamily:"sans-serif"}}>Update New Max Amount</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Max Amount"
            type="text"
            fullWidth
            value={newMaxAmount}
            onChange={handleChange}
            size="small"
          />
          <Typography>{autoBidMaxAmount && (
            <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold", fontFamily:"sans-serif" }}>
              Current Auto Max Amount: <span style={{ color: "#078586", fontFamily:"sans-serif" }}>{autoBidMaxAmount}</span>
            </Typography>
          )}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} size="small" sx={{
            background: "#2F4F4F", fontFamily:"sans-serif", color: "white", "&:hover": {
              background: "#078586",
            }
          }}>Cancel</Button>
          <Button sx={{
            background: "#2F4F4F", fontFamily:"sans-serif", color: "white", "&:hover": {
              background: "#078586",
            }
          }} onClick={handleUpdateMaxAmount} variant="contained" color="primary" size="small">Modify</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SellerUserAllBids;
