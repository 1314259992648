import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Card, CircularProgress } from '@mui/material';
import OtpImage from '../Login/Smarty.png';
import config from "../../configLoader";
import OtpInput from 'react-otp-input'
import BeforeLoginNavbar from '../BeforeLoginNavbar';

function OtpRegister() {
    const [NewOtp, setNewOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { mobile = '' } = location.state || {};
    const { allData = '' } = location.state || {};
    const { allWithoutGstData = '' } = location.state || {};
    const lastFourDigits = mobile.slice(-4);
    const maskedMobileNumber = "******" + lastFourDigits;
    const auth = JSON.parse(localStorage.getItem("result"));

    const fullData = allData;
    const Data = allWithoutGstData;

    const handleChangeOtp = (event) => {
        setNewOtp(event);
    };


    const handleClick = () => {
        setIsLoading(true);

        fetch(`${config.apiUrl}steel.v1.login.verify_new_user?mobile=${mobile}&otp=${NewOtp}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 200) {
                    if (allWithoutGstData) {
                        // If data is present in allWithoutGstData, call steel.api.create_new_profile
                        fetch(`${config.apiUrl}steel.api.create_new_profile_without_gst`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(Data)
                        })
                            .then(response => response.json())
                            .then((data) => {
                                if (data.status_code === 200) {
                                    localStorage.setItem('result', JSON.stringify(data));
                                    localStorage.setItem('key', JSON.stringify(data.key_details.api_key));
                                    localStorage.setItem('secret', JSON.stringify(data.key_details.api_secret));
                                    localStorage.setItem('mobile', JSON.stringify(data.profile_details[0].mobile));
                                    localStorage.setItem('userId', JSON.stringify(data.user_details[0].name));
                                    let userProfileData = data.user_details[0].role_profile_name;
                                    console.log(data.user_details[0].role_profile_name);
                                    if (userProfileData === 'Buyer and Seller') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Both'));
                                    } else if (userProfileData === 'Buyer') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Buyer'));
                                    } else if (userProfileData === 'Seller') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Seller'));
                                    }
                                    navigate('/profile');
                                } else {
                                    toast.error('Wrong OTP entered. enter correct OTP.');
                                }
                            })
                            .catch(error => console.error(error));
                    } else {
                        fetch(`${config.apiUrl}steel.api.create_new_profile`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(fullData)
                        })
                            .then(response => response.json())
                            .then((data) => {
                                if (data.message === 'Profile Added Successfully') {
                                    localStorage.setItem('result', JSON.stringify(data));
                                    localStorage.setItem('key', JSON.stringify(data.key_details.api_key));
                                    localStorage.setItem('secret', JSON.stringify(data.key_details.api_secret));
                                    localStorage.setItem('mobile', JSON.stringify(data.profile_details[0].mobile));
                                    localStorage.setItem('userId', JSON.stringify(data.user_details[0].name));
                                    let userProfileData = data.user_details[0].role_profile_name;
                                    console.log(data.user_details[0].role_profile_name);
                                    if (userProfileData === 'Buyer and Seller') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Both'));
                                    } else if (userProfileData === 'Buyer') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Buyer'));
                                    } else if (userProfileData === 'Seller') {
                                        localStorage.setItem('roleProfile', JSON.stringify('Seller'));
                                    }
                                    navigate('/profile');
                                } else {
                                    toast.error('Wrong OTP entered. enter correct OTP.');
                                }
                            })
                            .catch(error => console.error(error));
                    }
                } else {
                    toast.error("Wrong OTP, please enter correct OTP");
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (NewOtp.length === 6) {
            handleClick();
        }
    }, [NewOtp]);

    useEffect(() => {
        if (localStorage.getItem("result")) {
            navigate("/")
        }
    }, [])

    return (
        <>
            {!auth ?
                <>
                    <BeforeLoginNavbar />
                </>
                : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '85vh',
                }}
            >
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <Card
                    sx={{
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        height: "450px",
                        width: { xs: '90%', sm: '400px' },
                        margin: 'auto',
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: {
                            xs: 2,
                            sm: 5,
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={OtpImage} alt="Login Image" width="100" height="100" />
                        <Typography sx={{ fontSize: "17px", color: "#2F4F4F", mt: 3, fontFamily: "serif" }}>
                            OTP sent on your WhatsApp {maskedMobileNumber}
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontFamily: "sans-serif",
                                mb: 2,
                                mt: 3,
                                fontSize: {
                                    xs: '24px',
                                    sm: '28px',
                                },
                            }}
                        >
                            Verify OTP
                        </Typography>
                        <OtpInput
                            renderInput={(props, index) =>
                                <input
                                    {...props}
                                    type="tel"
                                    pattern="[0-9]*"
                                />
                            }
                            // onKeyPress={(e) => handleKeyPress(e)}
                            autoComplete="off"
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            value={NewOtp}
                            onChange={handleChangeOtp}
                            InputLabelProps={{ style: { fontSize: 15 } }}
                            inputStyle={{
                                border: "1px solid black",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                fontSize: "12px",
                                fontWeight: "400",
                                caretColor: "blue",
                                margin: "5px",
                            }}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                fontSize: '15px',
                                fontFamily: "serif",
                                textAlign: "center",
                                mt: 3,
                            }}
                        >
                            {isLoading ?
                                <>
                                    <CircularProgress size={20} />
                                    <Typography>Please Wait</Typography>
                                </>
                                : null}
                        </Box>
                    </Box>
                </Card>
            </Box>

        </>
    );
}

export default OtpRegister;
