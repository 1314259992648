import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import config from "../../../configLoader";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BuyerRequirement = () => {
  const navigate = useNavigate();
  const [AllData, setAllData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [CategoryTypeData, setCategoryTypeData] = useState([]);
  const [GradeTypeData, setGradeTypeData] = useState([]);
  const [requirementType, setRequirementType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const secret = JSON.parse(localStorage.getItem("secret"));
  const key = JSON.parse(localStorage.getItem("key"));
  const newName = JSON.parse(localStorage.getItem("result"));
  const name = newName.user_details[0].name;
  const [dateRange, setDateRange] = useState([null, null]);



  const fetchData = async () => {
    const response = await fetch(`${config.apiUrl}steel.api.get_requirement_by_seller`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${key}:${secret}`,
      },
           body: JSON.stringify
          ({
            user:name,
            requirement_type:requirementType,
            page: page + 1,
            size: rowsPerPage,
            status: filterStatus,
            category: filterCategory,
            grade: filterGrade,
            from_date:dateRange[0],
            to_date:dateRange[1]
          })
        }
      );

      const res = await response.json();
      const dataWithHoursLeft = res.data.requirement.map((row) => {
        const expirationDate = new Date(row.expiration_date);
        const currentDate = new Date();
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutesLeft = Math.floor(
          (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
        );
        return { ...row, hoursLeft, minutesLeft };
      });
      setAllData(dataWithHoursLeft);
      setTotalCount(res.data.total_requirement[0].count);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData((prevData) => {
        const updatedData = prevData.map((row) => {
          if (row.hoursLeft > 0 || row.minutesLeft > 0) {
            if (row.minutesLeft === 0) {
              if (row.hoursLeft === 0) {
                row.hoursLeft = 0;
                row.minutesLeft = 0;
              } else {
                row.hoursLeft -= 1;
                row.minutesLeft = 59;
              }
            } else {
              row.minutesLeft -= 1;
            }
          }
          return row;
        });
        return updatedData;
      });
    }, 60000); // Update every minute (60,000 milliseconds)

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [AllData]);

  useEffect(() => {
    const fetchRequirementType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_requirement_type`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${key}:${secret}`,
            },
          }
        );
        const data = await response.json();
        setRequirementType(data.data[0].type); // Set the requirementType state
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCategoryType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_category`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${key}:${secret}`,
            },
          }
        );
        const data = await response.json();
        setCategoryTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchGradeType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_grade`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${key}:${secret}`,
            },
          }
        );
        const data = await response.json();
        setGradeTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStatus = async () => {
      try {
        const result = await fetch(`${config.apiUrl}steel.api.get_requirement_status`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${key}:${secret}`,
            },
          }
        );
        const res = await result.json();
        setStatusData(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when the data is fetched
      }
    };

    fetchRequirementType();
    fetchStatus();
    fetchGradeType();
    fetchCategoryType();
  }, []);

  useEffect(() => {
    if (requirementType) {
      fetchData();
    }
  }, [requirementType, filterStatus, page, rowsPerPage, filterCategory, filterGrade, dateRange]);

  const handleClearFilters = () => {
    setFilterStatus('');
    setFilterCategory('');
    setFilterGrade('');
    setDateRange([null,null]);
    fetchData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (date) => {
    if (date && date[0] && date[1]) {
      const formattedStartDate = date[0].format('YYYY-MM-DD');
      const formattedEndDate = date[1].format('YYYY-MM-DD');
      setDateRange([formattedStartDate, formattedEndDate]);
    }
  };


  const EditRequirementData = (cardId) => {
    const selectedCardData = AllData.find((row) => row.name === cardId);

    if (selectedCardData) {
      const category = selectedCardData.category;
      switch (category) {
        case "Sheet/Plate":
          navigate(`/sheetplate/${cardId}`, { state: { selectedCardData } });
          break;
        case "Coil":
          navigate(`/coil/${cardId}`, { state: { selectedCardData } });
          break;
        case "Round Pipe":
          navigate(`/roundpipe/${cardId}`, { state: { selectedCardData } });
          break;
        case "Square Pipe":
          navigate(`/squarepipe/${cardId}`, { state: { selectedCardData } });
          break;
        case "Flat":
          navigate(`/flat/${cardId}`, { state: { selectedCardData } });
          break;
        case "Angle":
          navigate(`/angle/${cardId}`, { state: { selectedCardData } });
          break;
        case "Round Rod":
          navigate(`/roundrod/${cardId}`, { state: { selectedCardData } });
          break;
        case "Square/Hex Rod":
          navigate(`/squarerod/${cardId}`, { state: { selectedCardData } });
          break;
        case "Wire":
          navigate(`/wire/${cardId}`, { state: { selectedCardData } });
          break;
        default:
          // Handle the case when the category doesn't match any of the specified categories.
          break;
      }
    }
  };

  const DeleteRequirementData = (cardId) => {
    const apiUrl = `${config.apiUrl}steel.api.withdraw_requirement?requirement_name=${cardId}`;

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Successfully withrown requirement..")
          setTimeout(() => {
            window.location.reload();
          }, 2000)

        } else {
          toast.warn("Failed to withrown requirement..!");
        }
      })
      .catch((error) => {
        toast.warn("Network error:", error);
      });
  };

  //accept deal called function
  const AcceptDeal = (requirementId) => {
    const apiUrl = `${config.apiUrl}steel.api.close_requirement`;
    const requestBody = {
      requirement_name: requirementId
    };

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Requirement Closed Successfully");
        } else {
          toast.warn("Failed to withdraw requirement.");
        }
      })
      .catch((error) => {
        toast.warn("Network error:", error);
      });
  };


  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 10,
            ml: { xs: 0, sm: 25 },
            maxWidth: "auto",
            width: "auto",
          }}
        >
          <ToastContainer style={{ fontSize: "13px" }} />
          <Typography sx={{ fontSize: 25, fontWeight: "bold", ml: 3 }}>
            My Requirements
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              m: 3,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <FormControl
              sx={{
                width: { xs: "95%", sm: "150px" },
                mt: { xs: 2, sm: 1 },
                mr: { xs: 0, sm: 1 },
              }}
            >
              <InputLabel id="demo-multiple-name-label" size="small" sx={{ fontFamily:"sans-serif"}}>Status</InputLabel>
              <Select
                size="small"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterStatus}
                onChange={(event) => setFilterStatus(event.target.value)}
                input={<OutlinedInput label="Status" />}
                sx={{ width: "100%", fontFamily: "sans-serif" }}
              >
                {statusData.map((option) => (
                  <MenuItem key={option.status} value={option.status}>
                    {option.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: { xs: "95%", sm: "150px" },
                mt: 1,
                mr: { xs: 0, sm: 1 },
              }}
            >
              <InputLabel id="demo-multiple-name-label" size="small" sx={{ fontFamily:"sans-serif"}}>Category</InputLabel>
              <Select
                size="small"
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterCategory}
                onChange={(event) => setFilterCategory(event.target.value)}
                input={<OutlinedInput label="Category" />}
                sx={{ fontFamily:"sans-serif"}}
              >
                {CategoryTypeData.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{ width: { xs: "95%", sm: "150px" }, mt: 1, mr: 1 }}
            >
              <InputLabel id="demo-multiple-name-label" sx={{ fontFamily:"sans-serif"}} size="small">Grade</InputLabel>
              <Select
                size="small"
                sx={{ fontFamily:"sans-serif"}}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterGrade}
                onChange={(event) => setFilterGrade(event.target.value)}
                input={<OutlinedInput label="Grade" />}
              >
                {GradeTypeData.map((option) => (
                  <MenuItem key={option.grade} value={option.grade}>
                    {option.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['SingleInputDateRangeField']}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  onChange={handleDateChange}
                  format="DD-MM-YYYY"
                  value={dateRange}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              sx={{ ml: 1, mt:"12px", fontFamily:"sans-serif", background:"#2F4F4F",
              "&:hover": {
                background: "#2E8B57"
              }, }}
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </Box>
          <Box sx={{ mt: 2, px: 3, pb: 5 }}>
            <Grid container spacing={2}>
              {AllData.length === 0 ? (
                <Box sx={{ display: "flex", ml: { xs: 0, sm: 15 } }}>
                  <Typography
                    sx={{
                      p: 5,
                      color: "#B8390E",
                      fontSize: 24,
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily:"sans-serif"
                    }}
                  >
                    No data Found..! Please Post the Requirement
                  </Typography>
                </Box>
              ) : (
                AllData.map((row) => (
                  <Grid key={row.name} item xs={12} sm={6} md={3} lg={6}>
                    <Card
                      sx={{
                        py: 2,
                        px: 2,
                        borderRadius: "5px",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                        minWidth: "auto",
                        width: "auto",
                        height: { xs: "auto", sm: "auto" },
                        border: "1px solid #078586",
                        backgroundColor: "#F0FFF0"
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold", color: "#078586", fontFamily:"sans-serif", mt: 1, mb: 2, fontSize: 17, }}>
                        {row.title}
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Item sx={{ textAlign: "start", height: "275px", fontFamily:"sans-serif"}}><Grid container spacing={1}>
                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                              Grade:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                              {row.grade}
                            </Grid>
                            {row.category === "Round Pipe" ? (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  NB/OD:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.nb_od}
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  ERW/Seamless:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.erw_seamless}
                                </Grid>

                              </>
                            ) : null}
                            {row.category === "Round Pipe" ||
                              row.category === "Round Rod" ||
                              row.category === "Wire" ? (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Diameter:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.diameter}
                                </Grid>
                              </>
                            ) : null}
                            {row.category === "Round Rod" ||
                              row.category === "Square/Hex Rod" ||
                              row.category === "Wire" ? null : (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Thickness(mm):
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.thickness}
                                </Grid>
                              </>
                            )}
                            {row.category === "Round Pipe" ||
                              row.category === "Square Pipe" ||
                              row.category === "Round Rod" ||
                              row.category === "Square/Hex Rod" ||
                              row.category === "Wire" ? null : (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Width(mm):
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, fontFamily:"sans-serif"}}>
                                  {row.width_value}
                                </Grid>
                              </>
                            )}
                            {row.category === "Coil" ? null : (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Length(mm):
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.length_value}
                                </Grid>
                              </>
                            )}
                            {row.category === "Wire" || row.finish === null ? null : (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                  Finish:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                                  {row.finish}
                                </Grid>
                              </>
                            )}
                            {row.make === null ? null :
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Make:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.make}
                                </Grid>
                              </>
                            }
                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                              Quantity:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                              {row.quantity} ({row.uom})
                            </Grid>
                            {row.weight === 0 ? null :
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>Weight(kg):</Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>{row.weight}</Grid>
                              </>}

                            {row.category === "Wire" ? (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 }, }}>
                                  Hardness Type:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, }}>
                                  {row.hardness_type}
                                </Grid>
                              </>
                            ) : null}

                            {row.category === "Square/Hex Rod" ? (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                  Shape:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                                  {row.shape}
                                </Grid>
                              </>
                            ) : null}
                            {row.category === "Square Pipe" ||
                              row.category === "Square/Hex Rod" ? (
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                  Size:
                                </Grid>
                                {row.category === "Square/Hex Rod" ?
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}</Grid> :
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}x{row.size_2}</Grid>
                                }
                              </>
                            ) : null}

                          </Grid></Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item sx={{ textAlign: "start", height: "275px", fontFamily:"sans-serif"}}><Grid container spacing={1}>
                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                              Payment Terms:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                              {row.payment_terms}
                            </Grid>
                            {row.city === null || row.city === "" ? null :
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                  Delivery at:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                                  {row.city}
                                </Grid>
                              </>
                            }
                            {row.remarks === null || row.remarks === "" ? null :
                              <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                                  Remarks:
                                </Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                                  {row.remarks}
                                </Grid>
                              </>
                            }
                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                              Status:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                              {row.status}
                            </Grid>

                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                              Time Left:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" }, color: "#EE4B2B" }}>
                              {new Date() < new Date(row.expiration_date) ? (
                                row.hoursLeft > 24 ? (
                                  `${Math.floor(row.hoursLeft / 24)} days ${row.hoursLeft % 24} hours ${row.minutesLeft} minutes`
                                ) : (
                                  `${row.hoursLeft} hours ${row.minutesLeft} minutes`
                                )
                              ) : (
                                "Expired"
                              )}
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 13 } }}>
                              Best Bid:
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                              {row.lowest_bid}
                            </Grid>

                            <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 8, sm: 13 }, textAlign: { xs: "end", sm: "start" } }}>
                              {row.width}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              {
                                row.docstatus === 0 && row.status !== "Withdrawn" && row.hoursLeft > 0 ? (
                                  <Button size="small" variant="contained" onClick={() => EditRequirementData(row.name)} sx={{
                                    background: "#2F4F4F", color: "white", "&:hover": {
                                      background: "#078586",
                                    }, fontFamily:"sans-serif"
                                  }}>
                                    Edit
                                  </Button>
                                ) : null
                              }

                              {row.highest_bids === 'NO BIDS' && row.hoursLeft > 0 && row.status === 'Live' && (
                                <Button
                                  variant='contained'
                                  size="small"

                                  sx={{
                                    ml: 1, background: "#2F4F4F", color: "white", "&:hover": {
                                      background: "#078586",
                                    }, fontFamily:"sans-serif"
                                  }}
                                  onClick={() => DeleteRequirementData(row.name)}
                                >
                                  Withdraw
                                </Button>
                              )}
                              {row.lowest_bid === 'NO BIDS' ? null :
                                <>
                                  <Button size="small" variant="contained" sx={{
                                    fontSize: { xs: 8, sm: 12 },
                                    background: "#2F4F4F", color: "white", "&:hover": {
                                      background: "#078586"
                                    }, fontFamily:"sans-serif"
                                  }} onClick={() => AcceptDeal(row.name)} disabled={row.status === "Close"}>
                                    Accept
                                  </Button>
                                </>}
                            </Grid>
                          </Grid></Item>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
          {/* pagination  */}
          <Box sx={{ mt: 2 }}>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default BuyerRequirement;