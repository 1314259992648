import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import OtpImage from '../Login/Smarty.png';
import { Card } from '@mui/material';
import config from "../../configLoader";
import OtpInput from 'react-otp-input'
import BeforeLoginNavbar from '../BeforeLoginNavbar';

function OtpLogin() {
  const [otp, setOtp] = useState('');
  const auth = JSON.parse(localStorage.getItem("result"));
  const [categories, setCategories] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { mobileNumber = '' } = location.state || {};
  const lastFourDigits = mobileNumber.slice(-4);
  const maskedMobileNumber = '******' + lastFourDigits;

  const handleChangeOtp = (event) => {
    // Filter out non-numeric characters
    const numericValue = event.replace(/\D/g, '');
    setOtp(numericValue);
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleClick();
    }
  }, [otp]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(`${config.apiUrl}steel.api.get_single_profile?mobile=${mobileNumber}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const res = await result.json();
      setCategories(res.data[0].category);
      setAccountType(res.data[0].account_type);
    };
    fetchData();
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    fetch(`${config.apiUrl}steel.v1.login.verify_otp_code?mobile=${mobileNumber}&otp=${otp}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Logged In successfully') {
          localStorage.setItem('result', JSON.stringify(data));
          localStorage.setItem('key', JSON.stringify(data.key_details.api_key));
          localStorage.setItem('secret', JSON.stringify(data.key_details.api_secret));
          localStorage.setItem('mobile', JSON.stringify(data.profile_details[0].mobile));
          localStorage.setItem('userId', JSON.stringify(data.user_details[0].name));
          let userProfileData = data.user_details[0].role_profile_name;
          if (userProfileData === 'Buyer and Seller') {
            localStorage.setItem('roleProfile', JSON.stringify('Both'));
          } else if (userProfileData === 'Buyer') {
            localStorage.setItem('roleProfile', JSON.stringify('Buyer'));
          } else if (userProfileData === 'Seller') {
            localStorage.setItem('roleProfile', JSON.stringify('Seller'));
          }
          if ((accountType === 'Buyer' || accountType === 'Both') && categories.length !== 0) {
            navigate('/newrequirement');
          } else if (accountType === 'Seller' && categories.length !== 0) {
            navigate('/liverequirements');
          } else {
            navigate('/profile');
          }
        } else {
          toast.error('Wrong OTP entered. Enter correct OTP.');
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(()=>{
    if(localStorage.getItem("result")){
      navigate("/")
    }
  },[])

  return (
    <>
      {!auth ?
        <>
          <BeforeLoginNavbar />
        </>
        : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '85vh',
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Card
          sx={{
            height: "450px",
            width: { xs: '90%', sm: '400px' },
            margin: 'auto',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: {
              xs: 2,
              sm: 5,
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={OtpImage} alt="Login Image" width="100" height="100" />
            <Typography sx={{ fontSize: '17px', color: '#2F4F4F', mt: 3, fontFamily: "serif" }}>
              OTP sent on your WhatsApp {maskedMobileNumber}
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                mb: 2,
                mt: 3,
                fontSize: {
                  xs: '24px',
                  sm: '28px',
                },
              }}
            >
              Verify OTP
            </Typography>
            <OtpInput
              renderInput={(props, index) =>
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                />}
              autoComplete="off"
              numInputs={6}
              isInputNum={true}
              shouldAutoFocus={true}
              value={otp}
              onChange={handleChangeOtp}
              InputLabelProps={{ style: { fontSize: 15 } }}
              inputStyle={{
                border: "1px solid black",
                borderRadius: "8px",
                width: "40px",
                height: "40px",
                fontSize: "12px",
                fontWeight: "400",
                caretColor: "blue",
                margin: "5px",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none"
              }}
            />
            <Box
              sx={{
                width: '100%',
                fontSize: '15px',
                fontFamily: "serif",
                textAlign: "center",
                mt: 3,
              }}
            >
              {isLoading ? <CircularProgress size={20} /> : null}
            </Box>
            {/* <Button
            variant="contained"
            onClick={handleClick}
            disabled={isLoading}
            sx={{
              mt: 2,
              width: "100%",
              fontFamily: "serif",
              fontSize: '15px',
              backgroundColor: '#2F4F4F',
              '&:hover': {
                backgroundColor: '#2E8B57',
              },
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Next'}
          </Button> */}
          </Box>
        </Card>
      </Box>

    </>
  );
}

export default OtpLogin;
