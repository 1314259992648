import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QueueIcon from '@mui/icons-material/Queue';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import HistoryIcon from '@mui/icons-material/History';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import logo from './Smarty.png'
import axios from 'axios';
import config  from "../../configLoader.js";

const drawerWidth = 200;

function Sidebar(props) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const auth = JSON.parse(localStorage.getItem("result"));
  const userRole = JSON.parse(localStorage.getItem("roleProfile"));
  const [menuOpen, setMenuOpen] = useState(false);
  const [BuyerMenuOpen, setBuyerMenuOpen] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const [isComponentMounted, setIsComponentMounted] = useState(true);
  // const [profileData, setProfileData] = useState('');

  const timeoutRef = useRef(null);


  // useEffect(() => {
  //   const mobile = JSON.parse(localStorage.getItem("mobile"));
  //   const fetchData = async () => {
  //     const result = await fetch(`${config.apiUrl}steel.api.get_single_profile?mobile=${mobile}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       }
  //     })
  //     const res = await result.json();
  //     setProfileData(res.data[0].company_name);
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const apiUrl = `${config.apiUrl}steel.api.get_session_expiry_time`;

    // Function to reset the session timeout timer
    const resetSessionTimeout = () => {
      clearTimeout(timeoutRef.current);
      if (sessionTimeout) {
        const timeoutMilliseconds = sessionTimeout * 60000;
        timeoutRef.current = setTimeout(() => {
          if (isComponentMounted) {
            // Clear the session data from local storage
            localStorage.removeItem('result');
            localStorage.removeItem('secret');
            localStorage.removeItem('key');
            localStorage.removeItem('mobile');
            localStorage.removeItem("roleProfile");
            localStorage.removeItem('userId');

            // Navigate to the login page
            navigate('/login');
          }
        }, timeoutMilliseconds);
      }
    };

    // Fetch the session timeout and set the timer
    axios.get(apiUrl)
      .then(response => {
        const timeout = response.data.data.desktop;
        setSessionTimeout(timeout);
        resetSessionTimeout(); // Initial setup of the timer

        // Event listeners for user activity
        document.addEventListener('mousemove', resetSessionTimeout);
        document.addEventListener('keydown', resetSessionTimeout);

        return () => {
          // Cleanup: Remove event listeners and clear the timer
          document.removeEventListener('mousemove', resetSessionTimeout);
          document.removeEventListener('keydown', resetSessionTimeout);
          clearTimeout(timeoutRef.current);
          setIsComponentMounted(false);
        };
      })
      .catch(error => {
        console.error('Error fetching session timeout:', error);
      });
  }, [isComponentMounted, sessionTimeout]);

  // Add a new function to close the sidebar when a list item is clicked
  const handleListItemClick = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const handleBuyerMenuToggle = () => {
    setBuyerMenuOpen(!BuyerMenuOpen);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const getButtonStyle = (button) => ({
    fontFamily: 'serif',
    marginRight: '4px',
    marginTop: '12px',
  });

  const logOutUser = () => {
    localStorage.removeItem("result");
    localStorage.removeItem("key");
    localStorage.removeItem("secret");
    localStorage.removeItem("roleProfile");
    localStorage.removeItem("mobile");
    localStorage.removeItem("userId");
    navigate("/login");
  }
  const ShowUserProfile = () => {
    navigate("/profile");
  }
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawer = (
    <div>
      <Toolbar sx={{ backgroundColor: "white", color: "black", boxShadow: "0 0 10px rgba(0, 0, 0, 2)" }}>
      <a href='https://auction.smartysoft.in' style={{ textDecoration: "none", color: "black" }}>
        <img src={logo} width="70" height="60" style={{ marginLeft: "30px" }}/>
      </a>
      </Toolbar>
      <hr />
      <List sx={{ backgroundColor: "white" }}>


        {/* Buyer menu start */}
        {userRole === "Buyer" || userRole === "Both" ? (
          <>
            {userRole === "Both" ?
              <Typography sx={{ ml: 2, mb: 1, mt: 1, fontSize: 12, fontWeight: "bold", cursor: "pointer", fontFamily:"sans-serif" }} onClick={handleMenuToggle}>
                Requirement Posting & Tracking {menuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
              : <Typography sx={{ ml: 2, mb: 1, mt: 1, fontSize: 12, fontWeight: "bold", cursor: "pointer", fontFamily:"sans-serif" }}>Requirement Posting and Tracking</Typography>}
            <Divider />
            {(menuOpen || (userRole === "Buyer")) && (
              <>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/newrequirement'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <QueueIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Dashboard' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Post Requirement</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/buyerrequirement'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <ShoppingCartIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Biding' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>My Requirements</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/buyertransaction'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <ReceiptLongIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Biding' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Transaction History</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
          </>
        ) : null}
        {/* Buyer menu end */}

        {/* Sellers menu start*/}
        {userRole === "Seller" || userRole === "Both" ? (
          <>
            {userRole === "Both" ?
              <Typography sx={{ m: 2, fontSize: 12, fontWeight: "bold", cursor: "pointer", fontFamily:"sans-serif" }} onClick={handleBuyerMenuToggle}>
                Buyer’s Listing {BuyerMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Typography>
              : <Typography sx={{ m: 2, fontSize: 12, fontWeight: "bold", cursor: "pointer", fontFamily:"sans-serif" }}>Buyer’s Listing </Typography>}
            <Divider />
            {(BuyerMenuOpen || (userRole === "Seller")) && (
              <>
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/liverequirements'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <OpenInNewIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Requirements' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Live Requirements</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/bidstatus'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <MilitaryTechIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Biding' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Bid Status</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/sellerbidhistory'); handleListItemClick(); }}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: 'start',
                        color: "#078586"
                      }}
                    >
                      <HistoryIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Typography variant='Dashboard' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Bid History</Typography>
                    } />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
          </>
        ) : null}
        {/* Sellers menu end */}
        {userRole ?
          <>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/markettrends'); handleListItemClick(); }}>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 1,
                    justifyContent: 'start',
                    color: "#078586"
                  }}
                >
                  <TrendingUpIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary={
                  <Typography variant='Biding' sx={{ fontSize: 12, fontFamily:"sans-serif" }}>Market Trends</Typography>
                } />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
          : null}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    {auth?
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            background: "white",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography noWrap component="div" sx={{ fontSize: { xs: 8, sm: 25 }, fontWeight: "bold", fontFamily: "sans-serif" }}>
              <a href='https://auction.smartysoft.in' style={{ textDecoration: "none", color: "black" }}>
                Smarty Dalal
              </a>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
              {/* <Link to="/support"> */}
              <a href="https://wa.me/918302922212"><SupportAgentIcon sx={{ m: 2, color: "#078586" }} /></a>
              {/* </Link>  */}
              <Typography variant="h6" noWrap>
                {auth ? <div>
                  <Button
                    onClick={handleMenuOpen}
                    variant="text"
                    style={{ color: "black", fontFamily: 'lato' }}
                    aria-controls="menu"
                    aria-haspopup="true"
                  >
                    <Avatar sx={{ background: "#078586", mb: -2 }} src="/broken-image.jpg" />
                    <span style={{ marginLeft: '0.5rem', color: "#078586", fontFamily: "sans-serif",fontSize:"10px" }}>
                      {auth ? auth.full_name : null}
                    </span>
                  </Button>
                  {
                    userRole === "Buyer" ? (
                      <Typography sx={{ display: "flex", justifyContent: "flex-start", fontSize: "10px", ml: 7, color: "#078586", fontFamily: "sans-serif" }}>
                        Buyer
                      </Typography>
                    ) : userRole === "Seller" ? (
                      <Typography sx={{ display: "flex", justifyContent: "flex-start", fontSize: "10px", ml: 7, color: "#078586", fontFamily: "sans-serif" }}>
                        Seller
                      </Typography>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: "50px", marginTop: "-8px", color: "#078586", fontFamily: "sans-serif" }}>
                        <Typography sx={{ fontSize: "10px", ml: 1 }}>
                          Buyer and
                        </Typography>
                        <Typography sx={{ fontSize: "10px", marginLeft:"3px" }}>
                          Seller
                        </Typography>
                      </div>
                    )
                  }
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'right',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      style: {
                        width: '150px'
                      }
                    }}
                  >
                    <MenuItem onClick={() => { handleMenuClose(); ShowUserProfile(); }}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={() => { handleMenuClose(); logOutUser(); }}>
                      Logout
                    </MenuItem>

                  </Menu>
                </div> : <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="success"
                    variant={currentPath === '/login' ? 'contained' : 'outlined'}
                    onClick={() => {
                      handleLoginClick();
                    }}
                    style={getButtonStyle('login')}
                  >
                    Login
                  </Button>
                  <Button
                    size="small"
                    color="success"
                    variant={currentPath === '/register' ? 'contained' : 'outlined'}
                    onClick={() => {
                      handleRegisterClick();
                    }}
                    style={getButtonStyle('register')}
                  >
                    Register
                  </Button>
                </Box>
                }
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, //Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        {auth ?
          <>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
              <Toolbar />
              <Typography paragraph>

              </Typography>
              <Typography paragraph>

              </Typography>
            </Box>
          </> :
          null}
      </Box>
      </>
      :null}
    </>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;




