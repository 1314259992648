import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Card, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TablePagination from '@mui/material/TablePagination';
import config from "../../configLoader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function MarketTrande() {

  const [filterStatus, setFilterStatus] = useState('Live');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterGrade, setFilterGrade] = useState('');
  const [filterType, setFilterType] = useState('');
  const [requirementData, setRequirementData] = useState([]);
  const [CategoryTypeData, setCategoryTypeData] = useState([]);
  const [GradeTypeData, setGradeTypeData] = useState([]);
  const [StatusTypeData, setStatusTypeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState([null, null]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);


  const key = JSON.parse(localStorage.getItem('key'));
  const secret = JSON.parse(localStorage.getItem('secret'));

  useEffect(() => {

    const fetchData = async () => {
      const response = await fetch(`${config.apiUrl}steel.api.get_market_trends`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${key}:${secret}`,
        },
        body: JSON.stringify
          ({
            page: page + 1,
            size: rowsPerPage,
            status: filterStatus,
            category: filterCategory,
            grade: filterGrade,
            type: filterType,
            from_date: filterDate[0],
            to_date: filterDate[1]
          })
      });

      const responseData = await response.json();
      setRequirementData(responseData.data.requirement)
      setTotalCount(responseData.data.total_requirement);
      setLoading(false);
    };

    fetchData();
  }, [page, rowsPerPage, filterCategory, filterStatus, filterGrade, filterDate, filterType]);


  const handleClearFilters = () => {
    setFilterStatus('Live');
    setFilterCategory('');
    setFilterGrade('');
    setFilterType('');
    setFilterDate([null, null]);
  };

  useEffect(() => {
    const fetchCategoryType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_category`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${key}:${secret}`
          }
        });
        const data = await response.json();
        setCategoryTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchGradeType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_grade`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${key}:${secret}`
          }
        });
        const data = await response.json();
        setGradeTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchStatusType = async () => {
      try {
        const response = await fetch(`${config.apiUrl}steel.api.get_requirement_status_market_trends`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${key}:${secret}`
          }
        });
        const data = await response.json();
        setStatusTypeData(data.data);
      } catch (error) {
        console.error(error);
      }
    };
   
    fetchCategoryType();
    fetchGradeType();
    fetchStatusType();

  }, [key, secret]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (date) => {
    if (date && date[0] && date[1]) {
      const formattedStartDate = date[0].format('YYYY-MM-DD');
      const formattedEndDate = date[1].format('YYYY-MM-DD');
      setFilterDate([formattedStartDate, formattedEndDate]); // Update setFilterDate with an array of start and end dates
    }
  };


  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          {/* <Typography variant="h5" color="inherit" sx={{ ml: 2, color: "white", fontFamily:"sans-serif" }}>
            Please Wait...
          </Typography> */}
        </Box>
      ) : (
        <Box sx={{ mt: 10, ml: { xs: 2, sm: 27 }, mr: 2, pl: 1, pr: 1, width: "auto", borderRadius: "5px" }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", fontSize: "25px", mb: 2 }}>Market Trends</Typography>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, mb: 1 }}>
            <FormControl sx={{ width: { xs: "100%", sm: "130px" }, mt: 1, mr: { xs: 0, sm: 1 } }}>
              <InputLabel id="demo-multiple-name-label" size='small' sx={{ fontFamily:"sans-serif" }}>Status</InputLabel>
              <Select
                size='small'
                sx={{ fontFamily:"sans-serif" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterStatus}
                onChange={(event) => setFilterStatus(event.target.value)}
                input={<OutlinedInput label="Status" />}
              >
                {StatusTypeData.map((option) => (
                  <MenuItem key={option.status} value={option.status}>
                    {option.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", sm: "130px" }, mt: 1, mr: 1 }}>
              <InputLabel id="demo-multiple-name-label" size='small' sx={{ fontFamily:"sans-serif" }}>Category</InputLabel>
              <Select
                size='small'
                sx={{ fontFamily:"sans-serif" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterCategory}
                onChange={(event) => setFilterCategory(event.target.value)}
                input={<OutlinedInput label="Category" />}
              >
                {CategoryTypeData.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", sm: "130px" }, mt: 1, mr: 1 }}>
              <InputLabel id="demo-multiple-name-label" size='small' sx={{ fontFamily:"sans-serif" }}>Grade</InputLabel>
              <Select
                size='small'
                sx={{ fontFamily:"sans-serif" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={filterGrade}
                onChange={(event) => setFilterGrade(event.target.value)}
                input={<OutlinedInput label="Grade" />}
              >
                {GradeTypeData.map((option) => (
                  <MenuItem key={option.grade} value={option.grade}>
                    {option.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['SingleInputDateRangeField']}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  onChange={handleDateChange}
                  format="DD-MM-YYYY"
                  value={filterDate}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button sx={{
                ml: 1, mt: "12px", mb: 1, background: "#2F4F4F", "&:hover": {
                  background: "#2E8B57"
                }
              }} size="small" variant="contained" color="secondary" onClick={handleClearFilters}>
                Clear
              </Button>
            </Box>

          </Box>
          <Box >
            <Grid container spacing={2}>
              {requirementData.length === 0 ? (
                <Box sx={{ display: "flex", ml: { xs: 2, sm: 35 } }}>
                  <Typography
                    sx={{
                      p: 10,
                      color: "#B8390E",
                      fontSize: 24,
                      fontWeight: 'bold',
                      textAlign: "center",
                      fontFamily:"serif"
                    }}
                  >No data Found..!</Typography>
                </Box>
              ) : (
                requirementData.map((row) => (
                  <Grid key={row.name} item xs={12} sm={6} md={3} lg={6}>
                    <Card sx={{
                      py: 2, px: 2, borderRadius: "5px", boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)", width: "auto", height: { xs: "auto", sm: "350px" },
                      border: "1px solid #078586",
                      backgroundColor: "#F0FFF0"
                    }}>
                      <Typography sx={{ pb: 3, fontSize: 16, fontWeight: "bold", color: "#078586", fontFamily:"sans-serif" }}>{row.title}</Typography>



                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Item sx={{ textAlign: "start", height: "275px", fontFamily:"sans-serif" }}>
                            <Grid container spacing={1}>
                              {row.grade === null ? null :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Grade:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.grade}</Grid>
                                </>
                              }
                              {row.category === "Round Pipe" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>NB/OD:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.nb_od}</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>ERW/Seamless:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.erw_seamless}</Grid>
                                </> :
                                null}
                              {row.category === "Round Pipe" || row.category === "Round Rod" || row.category === "Wire" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, }}>Diameter:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.diameter}</Grid>
                                </> :
                                null
                              }
                              {row.category === "Round Rod" || row.category === "Square/Hex Rod" || row.category === "Wire" ?
                                null :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Thickness(mm):</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.thickness}</Grid>
                                </>
                              }
                              {row.category === "Coil" ?
                                null
                                :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Length(mm):</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.length_value}</Grid>
                                </>
                              }
                              {row.category === "Wire" || row.finish === null || row.finish === "" ? null : <>
                                <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, }}>Finish:</Grid>
                                <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.finish}</Grid>
                              </>}
                              {row.make === null && row.make === "" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Make:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" }, }}>{row.make}</Grid>
                                </>
                                : null}
                              <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Quantity:</Grid>
                              <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.quantity} ({row.uom})</Grid>

                              {row.category === "Square Pipe" || row.category === "Square/Hex Rod" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 }, }}>Size:</Grid>
                                  {row.category === "Square/Hex Rod" ?
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}</Grid> :
                                    <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.size}x{row.size_2}</Grid>
                                  }
                                </>
                                : null}
                              {row.category === "Round Pipe" || row.category === "Square Pipe" || row.category === "Round Rod" || row.category === "Square/Hex Rod" || row.category === "Wire" ?
                                null :
                                <><Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Width(mm):</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.width_value}</Grid>
                                </>
                              }
                              {row.category === "Square/Hex Rod" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Shape:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.shape}</Grid>
                                </>
                                : null}

                              {row.category === "Wire" ?
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Hardness Type:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.hardness_type}</Grid>
                                </> :
                                null}
                              {row.weight === 0 ? null :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Weight(kg):</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.weight}</Grid>
                                </>}

                            </Grid>
                          </Item>
                        </Grid>
                        <Grid item xs={6}>
                          <Item sx={{ textAlign: "start", height: "275px", fontFamily:"sans-serif" }}>
                            <Grid container spacing={1}>

                              {row.requirement_type === "I want to Buy" ? (
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Lowest Bid:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.lowest_bid}</Grid>
                                </>
                              ) :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Highest Bid:</Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.highest_bids}</Grid>
                                </>}
                              <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>
                                Payment Terms:
                              </Grid>
                              <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                {row.payment_terms}
                              </Grid>

                              <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Status:</Grid>
                              <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{row.status}</Grid>
                              {row.city === null || row.city === "" ? null :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>
                                    Delivery at:
                                  </Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                    {row.city}
                                  </Grid>
                                </>}
                              {row.remarks === null || row.remarks === "" ? null :
                                <>
                                  <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>
                                    Remarks:
                                  </Grid>
                                  <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>
                                    {row.remarks}
                                  </Grid>
                                </>
                              }
                              <Grid item xs={6} sm={6} sx={{ fontWeight: "bold", fontSize: { xs: 10, sm: 12 } }}>Expiration:</Grid>
                              <Grid item xs={6} sm={6} sx={{ fontSize: { xs: 10, sm: 12 }, textAlign: { xs: "end", sm: "start" } }}>{format(new Date(row.expiration_date), 'dd-MM-yy hh:mm a')}</Grid>
                            </Grid></Item>
                        </Grid>
                      </Grid>



                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
          {/* pagination  */}
          <Box sx={{ mt: 2 }}>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>

        </Box>
      )}
    </>
  );
}


