import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from '../Components/Sidebar/Smarty.png'
import { useNavigate, useLocation } from 'react-router-dom';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function BeforeLoginNavbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const location = useLocation();
    const currentPath = location.pathname;

    const navigate = useNavigate();


    const handleLoginClick = () => {
        navigate("/login");
    };

    const handleRegisterClick = () => {
        navigate("/register");
    };

    const getButtonStyle = (button) => ({
        fontFamily: 'serif',
        marginRight: '4px',
        marginTop: '12px',
    });

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "white", color: "black", }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <a href='https://auction.smartysoft.in' style={{ textDecoration: "none", color: "black" }}>
                        <img src={logo} alt="logo" width="70" height="60" />
                    </a>

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            ml: 3,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                            fontSize: 25,
                            color: 'inherit',
                            textDecoration: 'none',
                            "&:hover": {
                                color: "black",
                            },

                        }}
                    >
                        <a href='https://auction.smartysoft.in' style={{ textDecoration: "none", color: "black" }}>Smarty Dalal</a>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 5,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 4,
                            fontFamily: 'sans-serif',
                            fontWeight: 700,
                            color: 'inherit',
                            fontSize: 13,
                            textDecoration: 'none',
                            "&:hover": {
                                color: "black",
                            },
                        }}
                    >
                        <a href='https://auction.smartysoft.in' style={{ textDecoration: "none", color: "black" }}>
                            Smarty Dalal
                        </a>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    </Box>

                    <Box sx={{ display: { xs: "flex", sm: "inline" } }}>
                        <Button
                            size="small"
                            color="success"
                            variant={currentPath === '/login' ? 'contained' : 'outlined'}
                            onClick={() => {
                                handleLoginClick();
                            }}
                            style={getButtonStyle('login')}
                        >
                            Login
                        </Button>
                        <Button
                            size="small"
                            color="success"
                            variant={currentPath === '/register' ? 'contained' : 'outlined'}
                            onClick={() => {
                                handleRegisterClick();
                            }}
                            style={getButtonStyle('register')}
                        >
                            Register
                        </Button>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default BeforeLoginNavbar;
