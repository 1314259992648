import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import login from './Smarty.png';
import { Card } from '@mui/material';
import config from "../../configLoader";
import BeforeLoginNavbar from '../BeforeLoginNavbar';
import { useEffect } from 'react';

function MobileLogin() {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const auth = JSON.parse(localStorage.getItem("result"));


  useEffect(()=>{
    if(localStorage.getItem("result")){
      navigate("/")
    }
  },[])


  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
    setError(!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(event.target.value));
  };

  const handleSendOTPClick = () => {
    setIsLoading(true);
    fetch(`${config.apiUrl}steel.v1.login.login?mobile=${mobileNumber}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          navigate('/otplogin', { state: { mobileNumber } });
        } else {
          toast.warn("Your mobile number is not registered, please register first!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: {
              fontSize: "14px",
            },
          });
          setTimeout(() => {
            // navigate("/gst");
            navigate('/register', { state: { mobileNumber } })
          }, 3000);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendOTPClick(event);
    }
  };

  return (
    <>
      {!auth ?
        <>
          <BeforeLoginNavbar/>
        </>
        : null}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '85vh',
        }}
      >
        <ToastContainer />
        <Card
          sx={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            height: "450px",
            width: { xs: '90%', sm: '400px' },
            margin: 'auto',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: {
              xs: 2,
              sm: 5,
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={login} alt="Login Image" width="150" height="150" style={{ marginBottom: "20px" }} />
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: "sans-serif",
                fontWeight: 'bold',
                mb: 2,
                fontSize: {
                  xs: '24px',
                  sm: '30px',
                },
              }}
            >
              Login
            </Typography>
            <TextField
              onKeyPress={(e) => handleKeyPress(e)}
              sx={{
                width: '100%',
                mb: 2,
              }}
              autoComplete="off"
              fullWidth
              id="mobileNumber"
              label="Enter Mobile No.."
              name="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              InputLabelProps={{ style: { fontFamily: "serif" } }}
              InputProps={{
                sx: {
                  height: '50px',
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleSendOTPClick}
              disabled={error || isLoading}
              sx={{
                width: '100%',
                fontSize: '15px',
                fontFamily: "serif",
                background: "#2F4F4F",
                "&:hover": {
                  background: "#2E8B57",
                },
              }}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Send OTP'}
            </Button>
            <Box sx={{ display: "flex", flexDirection: "row", ml: -5 }}>
              <Typography sx={{
                fontSize: 12,
                fontWeight: "bold", mt: 1, cursor: 'pointer', color: "#2F4F4F",
              }}>Not registered?</Typography> <Typography sx={{
                fontSize: 12,
                fontWeight: "bold", mt: 1, cursor: 'pointer', color: "#4169E1",
                "&:hover": {
                  color: "#00008B",
                },
              }} onClick={() => navigate('/register')}>&nbsp;Register here.</Typography>
            </Box>
          </Box>
        </Card>
      </Box>

    </>
  );
}

export default MobileLogin;
