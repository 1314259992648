import React, { useState, useEffect } from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCardText,
    MDBCardBody,
} from 'mdb-react-ui-kit';
import { Card, Checkbox, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import config from "../../configLoader";


export default function Profile() {

    const [profileData, setProfileData] = useState({ account_type: [] });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const key = JSON.parse(localStorage.getItem('key'));
    const secret = JSON.parse(localStorage.getItem('secret'));
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const RoleProfile = JSON.parse(localStorage.getItem('roleProfile'));
    const [buyerChecked, setBuyerChecked] = useState();
    const [sellerChecked, setSellerChecked] = useState();
    const [sheetPlateChecked, setSheetPlateChecked] = useState(false);
    const [coilChecked, setCoilChecked] = useState(false);
    const [roundPipeChecked, setRoundPipeChecked] = useState(false);
    const [squarePipeChecked, setSquarePipeChecked] = useState(false);
    const [flatChecked, setFlatChecked] = useState(false);
    const [angleChecked, setAngleChecked] = useState(false);
    const [roundRodChecked, setRoundRodChecked] = useState(false);
    const [squareRodChecked, setSquareRodChecked] = useState(false);
    const [wireChecked, setWireChecked] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const itemYourDealColor = categories.length === 0 ? 'red' : 'black';

    const addCategoryToSelected = (category) => {
        setSelectedCategories((prevSelected) => {
            if (!prevSelected.includes(category)) {
                return [...prevSelected, category];
            }
            return prevSelected;
        });
    };

    useEffect(() => {
        const roleProfile = RoleProfile || profileData.account_type;

        if (roleProfile === 'Both') {
            setBuyerChecked(true);
            setSellerChecked(true);
        } else if (roleProfile === 'Buyer') {
            setBuyerChecked(true);
        } else if (roleProfile === 'Seller') {
            setSellerChecked(true);
        }
    }, [RoleProfile, profileData.account_type]);


    const handleBuyerChange = () => {
        const newBuyerChecked = !buyerChecked;
        setBuyerChecked(newBuyerChecked);
        localStorage.setItem('buyerChecked', newBuyerChecked.toString());
        setCheckboxChecked(true);
    };

    const handleSellerChange = () => {
        const newSellerChecked = !sellerChecked;
        setSellerChecked(newSellerChecked);
        localStorage.setItem('sellerChecked', newSellerChecked.toString());
        setCheckboxChecked(true);
    };

    useEffect(() => {
        const mobile = JSON.parse(localStorage.getItem("mobile"));
        const fetchData = async () => {
            const result = await fetch(`${config.apiUrl}steel.api.get_single_profile?mobile=${mobile}`, {
                headers: {
                    "Content-Type": "application/json",
                }
            })
            const res = await result.json();
            setProfileData(res.data[0]);
            setCategories(res.data[0].category);
            setLoading(false);

            const serverCategories = res.data[0].category.map(item => item.category);
            setSheetPlateChecked(serverCategories.includes('Sheet/Plate'));
            setCoilChecked(serverCategories.includes('Coil'));
            setRoundPipeChecked(serverCategories.includes('Round Pipe'));
            setSquarePipeChecked(serverCategories.includes('Square Pipe'));
            setFlatChecked(serverCategories.includes('Flat'));
            setAngleChecked(serverCategories.includes('Angle'));
            setRoundRodChecked(serverCategories.includes('Round Rod'));
            setSquareRodChecked(serverCategories.includes('Square/Hex Rod'));
            setWireChecked(serverCategories.includes('Wire'));
        };
        fetchData();
    }, []);

    //account type update function
    const handleSaveProfile = async () => {
        if (!checkboxChecked && !isUpdateDisabled) {
            toast.warn("You haven't updated anything yet!");
            return;
        }
        const updatedProfileData = { ...profileData, account_type: getAccountType() };
        const result = await fetch(`${config.apiUrl}steel.api.update_profile`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `token ${key}:${secret}`,
                },
                body: JSON.stringify(updatedProfileData),
            }
        );
        const res = await result.json();
        toast.success(`Account Updated Successfully..!`);
        const roleProfile = JSON.stringify(getAccountType());
        localStorage.setItem("roleProfile", roleProfile);
        setTimeout(() => {
            window.location.reload();
        }, 800);
    };

    const getAccountType = () => {
        if (buyerChecked && sellerChecked) {
            return "Both";
        } else if (buyerChecked) {
            return "Buyer";
        } else if (sellerChecked) {
            return "Seller";
        } else {
            return "";
        }
    };

    const isUpdateDisabled = RoleProfile === "Both";

    //categories update function
    const handleSaveCategory = async () => {
        // Filter out unchecked checkboxes and get only checked checkboxes
        const updatedSelectedCategories = [
            sheetPlateChecked && 'Sheet/Plate',
            coilChecked && 'Coil',
            roundPipeChecked && 'Round Pipe',
            squarePipeChecked && 'Square Pipe',
            flatChecked && 'Flat',
            angleChecked && 'Angle',
            roundRodChecked && 'Round Rod',
            squareRodChecked && 'Square/Hex Rod',
            wireChecked && 'Wire',
        ].filter(Boolean);

        const updatedProfileData = {
            ...profileData,
            category: updatedSelectedCategories.map((category) => ({ category })),
        };

        const result = await fetch(`${config.apiUrl}steel.api.update_profile_v1`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${key}:${secret}`,
                },
                body: JSON.stringify(updatedProfileData),
            }
        );
        const res = await result.json();
        toast.success('Account Updated Successfully..!');
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    // Function to handle adding a category to selectedCategories
    const handleAddCategory = (category) => {
        addCategoryToSelected(category);
    };


    return (
        <>
            <Card sx={{
                mt: 10,
                ml: { xs: 2, sm: 30 },
                mr: { xs: 2, sm: 10 },
                maxWidth: 'auto',
                width: 'auto',
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
                border: "1px solid #078586",
                backgroundColor: "#F0FFF0"
            }}>
                <ToastContainer  style={{fontSize:"14px"}}/>
                <Box sx={{ px: 3 }}>
                    <Grid container spacing={2}>
                        <MDBContainer className="py-5">
                            <MDBRow>
                                <Grid item xs={12} sm={12}>
                                    {loading ? (
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={loading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    ) : (
                                        <MDBCol>
                                            <MDBCardBody style={{ fontFamily: "sans-serif" }}>
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>Account Type</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={buyerChecked}
                                                            onChange={handleBuyerChange}
                                                        />
                                                        Buyer
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={sellerChecked}
                                                            onChange={handleSellerChange}
                                                        />
                                                        Seller
                                                        <Button
                                                            sx={{
                                                                float: "right", mt: 1, background: "#2F4F4F", color: "white", "&:hover": {
                                                                    background: "#078586",
                                                                }, fontFamily: "sans-serif"
                                                            }}
                                                            variant="outlined"
                                                            onClick={handleSaveProfile}
                                                            size='small'
                                                        >
                                                            Update
                                                        </Button>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText style={{ color: itemYourDealColor }}>Item Your Deal In</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={sheetPlateChecked}
                                                            onChange={() => {
                                                                setSheetPlateChecked(!sheetPlateChecked);
                                                                localStorage.setItem('sheetPlateChecked', JSON.stringify(!sheetPlateChecked));
                                                                if (!sheetPlateChecked) {
                                                                    handleAddCategory('Sheet/Plate');
                                                                }
                                                            }}
                                                        />
                                                        Sheet/Plate
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={coilChecked}
                                                            onChange={() => {
                                                                setCoilChecked(!coilChecked);
                                                                localStorage.setItem('coilChecked', JSON.stringify(!coilChecked));
                                                                if (!coilChecked) {
                                                                    handleAddCategory('Coil');
                                                                }
                                                            }}
                                                        />
                                                        Coil
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={roundPipeChecked}
                                                            onChange={() => {
                                                                setRoundPipeChecked(!roundPipeChecked);
                                                                localStorage.setItem('roundPipeChecked', JSON.stringify(!roundPipeChecked));
                                                                if (!roundPipeChecked) {
                                                                    handleAddCategory('Round Pipe');
                                                                }
                                                            }}
                                                        />
                                                        Round Pipe

                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={squarePipeChecked}
                                                            onChange={() => {
                                                                setSquarePipeChecked(!squarePipeChecked);
                                                                localStorage.setItem('squarePipeChecked', JSON.stringify(!squarePipeChecked));
                                                                if (!squarePipeChecked) {
                                                                    handleAddCategory('Square Pipe');
                                                                }
                                                            }}
                                                        />
                                                        Square Pipe
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={flatChecked}
                                                            onChange={() => {
                                                                setFlatChecked(!flatChecked);
                                                                localStorage.setItem('flatChecked', JSON.stringify(!flatChecked));
                                                                if (!flatChecked) {
                                                                    handleAddCategory('Flat');
                                                                }
                                                            }}
                                                        />
                                                        Flat
                                                        <br />
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={angleChecked}
                                                            onChange={() => {
                                                                setAngleChecked(!angleChecked);
                                                                localStorage.setItem('angleChecked', JSON.stringify(!angleChecked));
                                                                if (!angleChecked) {
                                                                    handleAddCategory('Angle');
                                                                }
                                                            }}
                                                        />
                                                        Angle
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={roundRodChecked}
                                                            onChange={() => {
                                                                setRoundRodChecked(!roundRodChecked);
                                                                localStorage.setItem('roundRodChecked', JSON.stringify(!roundRodChecked));
                                                                if (!roundRodChecked) {
                                                                    handleAddCategory('Round Rod');
                                                                }
                                                            }}
                                                        />
                                                        Round Rod
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={squareRodChecked}
                                                            onChange={() => {
                                                                setSquareRodChecked(!squareRodChecked);
                                                                localStorage.setItem('squareRodChecked', JSON.stringify(!squareRodChecked));
                                                                if (!squareRodChecked) {
                                                                    handleAddCategory('Square/Hex Rod');
                                                                }
                                                            }}
                                                        />
                                                        Square/Hex Rod
                                                        <Checkbox
                                                            style={{
                                                                color: "#078586",
                                                            }}
                                                            checked={wireChecked}
                                                            onChange={() => {
                                                                setWireChecked(!wireChecked);
                                                                localStorage.setItem('wireChecked', JSON.stringify(!wireChecked));
                                                                if (!wireChecked) {
                                                                    handleAddCategory('Wire');
                                                                }
                                                            }}
                                                        />
                                                        Wire
                                                        <Box sx={{ float: "right" }}>
                                                            <Button sx={{
                                                                ml: 1, mt: 1, background: "#2F4F4F", color: "white", "&:hover": {
                                                                    background: "#078586",
                                                                }, fontFamily: "sans-serif"
                                                            }} size='small' variant="outlined" onClick={handleSaveCategory}>
                                                                Update
                                                            </Button>
                                                        </Box>
                                                    </MDBCol>

                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>Legal Name</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <MDBCardText className="text-muted">{profileData.first_name}</MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>Company Name</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <MDBCardText className="text-muted">{profileData.company_name}</MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>Mobile</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <MDBCardText className="text-muted">{profileData.mobile}</MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>GST NO</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <MDBCardText className="text-muted">{profileData.gst_no}</MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                                <MDBRow>
                                                    <MDBCol sm="3">
                                                        <MDBCardText>Address</MDBCardText>
                                                    </MDBCol>
                                                    <MDBCol sm="9">
                                                        <MDBCardText className="text-muted">{profileData.address}</MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>
                                                <hr />
                                            </MDBCardBody>
                                        </MDBCol>
                                    )}
                                </Grid>
                            </MDBRow>
                        </MDBContainer>
                    </Grid>
                </Box>
            </Card>
        </>
    );
}